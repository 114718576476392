
import { Form } from 'react-router-dom'

const Contact = () => {
  return (
    <div className="container mb-5">

        <h5>Contact</h5>
        <Form method="post" style={{width:"50%"}}>
          <div className="mt-2">
            <label className="form-label">
              Name
            </label>
            <input type="text" name="name" className="form-control"/>
          </div>
          <div className="mt-2">
            <label className="form-label">
              Email
            </label>
            <input type="email" name="email" className="form-control"/>
          </div>

          <div className="mt-3">
            <button className="btn btn-primary">Submit</button>
          </div>
        </Form>
    </div>
  )
}


export const contactFormAction = async ( {request} ) => {
  const data = await request.formData()
  const submission = {}
  return true
}
export default Contact