
import { useLoaderData, Link } from 'react-router-dom'

const Raid = () => {
    const raids = useLoaderData()


    return (
        <div className="container">{
            raids.map((raid) => {
                return (<p key={raid.id}><Link to={raid.id.toString()}>{raid.token_name}</Link></p>)
            })}
        </div>
    )
}

export const raidLoader = async () => {
    const res = await fetch('http://localhost:4000/tokens')
    return res.json()
}



export default Raid