import logo from './logo.svg';
import './App.css';
import { token, newprojects } from './dummy'
import { _HandleUpVote } from './handlers';
import { NavLink } from 'react-router-dom';



function App() {

  return (
    <section className="container">


      {/*<----PreBody ---->*/}
      <div className="row">
        <p className="mt-4 p-5" style={{backgroundColor:"#8fbc8f"}}>
          <h6 className="text-white">Advertise here!</h6>
        </p>
        <h4 className="text-center text-secondary">Launching Sooon!</h4>
        <div className="" style={{display:"flex",justifyContent:"space-evenly", flexWrap:"wrap"}}>
        {
          newprojects && newprojects.map((project) => {
            return (
             
              <NavLink className="nav-link col-sm m-1" key={project.id} to={"soon_launch/" +  project.id} > 
                <span>
                  <img className="flex-sm text-center" src="http://placehold.it/55" alt=""st/>
                  <p>{project.title}</p>
                  <p> Launch date: {project.launch_date}</p>
                  <p> Chain: {project.chain}</p>
                </span>
              </NavLink>
             
            )
          })
        }
   </div>
      </div>
      <div className="mt-5">
        <h1>Top Squads, Battalions, and Platoons!</h1>

        <div className="row">
          {
            token && token.map((t) => (
              <div className="card bg-light" style={{ width: "295px", margin: "1em", overflow: "hidden", border: "none" }}>
                <span style={{ width: "78px", borderRadius: "15px 50px 0px 50px", marginLeft: "auto", marginRight: 0, marginTop: "5px" }} className="badge bg-warning">votes {t.token_votes}</span>
                <img className="card-img-top" src="img_avatar1.png" alt="Card image" />
                <div className="card-body">
                  <h4 className="card-title">{t.token_name}</h4>
                  <p className="card-tex">Launch date: {t.launch_date}</p>
                  <p className="card-tex">Contract(networkicon): {t.token_contract_address}</p>
                  <p className="card-tex">Community trust: {t.token_trust}</p>
                  <p className="card-tex">Token category: {t.token_cat}</p>

                  <button className="btn btn-warning btn-sm" onClick={_HandleUpVote}>Up-vote</button>
                </div>
              </div>

            ))
          }



          <div className="p-4" style={{backgroundColor:"#2f4f4f"}}>
            <h3 className='text-white'>
              Content
            </h3>

          </div>
        </div>
      </div>

    </section>
  );
}

export default App;
