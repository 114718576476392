import { useLoaderData } from "react-router-dom"


const RaidDetails = () => {

    
    const raidDetail = useLoaderData()
    
  return (
    <div className="container">
        <h4>{raidDetail.token_name}</h4>
    </div>
  )
}


export const raidDetailsLoader = async ({params}) => {
    const { id } = params
    
    
    const res = await fetch('http://localhost:4000/tokens/' + id.toString())
    return res.json()
}

export default RaidDetails