import { NavLink } from "react-router-dom"

const Footer = () => {
    return (
        <div className="container bg-light" >
            <div className="row">
            <hr />
            {/* <-Footer content--> */}
            <div style={{ marginTop: "5%" }} className="col">
                <h4>Promote Here!</h4>
                <ul className="navbar-nav" style={{color:"#8fbc8f", fontWeight:500}}>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="promote">List your project</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="#">Start an army</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="raids">Join a raid</NavLink>
                    </li>

                </ul>


            </div>
            <div style={{ marginTop: "5%" }} className="col" >
                <h4>Contact us</h4>
                <ul className="navbar-nav" style={{color:"#8fbc8f", fontWeight:500}}>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="about">Help and support</NavLink>
                    </li>

                </ul>


            </div>
            <div style={{ marginTop: "5%" }} className="col">
                <h4>Join us!</h4>
                <ul className="navbar-nav" style={{color:"#8fbc8f", fontWeight:500}}>
                    <li className="nav-item">
                        <a className="nav-link" target="blank" href="https://twitter.com/">Twitter</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" target="blank" href="https://discord.com/">Discord</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" target="blank" href="https://telegram.org/">Telegram</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" target="blank" href="https://reddit.com/">Reddit</a>
                    </li>
                </ul>

                </div>
            </div>
        </div>
    )
}

export default Footer