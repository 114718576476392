import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom'
import ErrorPage from './ErrorPage';
import Header from './components/Header';
import About from './pages/about/About';
import Raids from './pages/raids/Raids'
import Raid, {raidLoader}from './pages/raids/Raid';
import Promote, { promoteAction } from './pages/Promote'
import Calendar from './pages/Calendar'
import Trending from './pages/Trending'
import Faq from './pages/about/Faq';

import Contact from './pages/Contact'
import RaidDetails from './pages/raids/RaidDetails';
import { raidDetailsLoader } from './pages/raids/RaidDetails';





const router = createBrowserRouter(createRoutesFromElements(
  <Route path="/" element={< Header />}>
    <Route index element={<App />} />
      <Route path="about" element={<About />} > 
          <Route  path="faq" element={<Faq />} />
          
          <Route  path="contact" element={<Contact />} />
      </Route>

      <Route path="raids" element={<Raids />} >
        <Route index element={<Raid />} loader={raidLoader}/>
        <Route path=":id" element={<RaidDetails/>} loader={raidDetailsLoader} />
      </Route>
     
      <Route path="promote" element={<Promote />} action={ promoteAction}/>
      <Route path="calendar" element={<Calendar />} />
      <Route path="trending" element={<Trending />} />

    <Route path="*" element={<ErrorPage />} />
    
  </Route>
))

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />


  </React.StrictMode>
);


