import { Outlet, NavLink } from "react-router-dom"
import Footer from './Footer';
import mying from '../resources/images/pngegg.png'

const Header = () => {
    return (
        <>
        <div className="container text-center">
       <img className="img img-responsive m-3" style={{width:"14%"}} src={mying}alt=""/>
                </div> 
       
            <div className="text-center text-light p-5 container" style={{backgroundColor:"#008b8b", opacity:'75%'}}>
            
                <h3>Raid your favorite tokens!. </h3>
                <h4>Let the might of your army be felt!</h4>
            </div>
                <div className="container">

                    
                

                    <nav className="navbar navbar-expand-sm bg-light sm:hidden">
                    <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                    <span className="navbar-toggler-icon"></span>
                </button>
                        <div className="container">

                            <ul className="navbar-nav collapse navbar-collapse" id="navbarCollapse">
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/">Home</NavLink>
                                </li >
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="raids">Raids</NavLink>
                                </li >
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="promote">Promote</NavLink>
                                </li >
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="calendar">Calendar</NavLink>
                                </li >
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="trending">Most Trending</NavLink>
                                </li >
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="about">About</NavLink>
                                </li >

                            </ul>
                        </div>

                    </nav>
                </div>

            

            <Outlet />
            <Footer />
        </>
    )
}

export default Header