import { Outlet } from "react-router-dom"

const Raids = () => {
  return (
    <div className="container"><h4>Raids</h4>
    <Outlet />
    </div>
  )
}

export default Raids