import React from 'react'

const Calendar = () => {
  return (
    <div className="container">
      <h3>Calendar</h3>
    </div>
  )
}

export default Calendar