import { Form } from 'react-router-dom'

const Promote = () => {
  return (
    <div className="container">
      <h5>Promote your project!</h5>

      <Form method="post" action="/promote">
        <div className="mt-3">
          <label className="form-label">
            Token/Project Name
          </label>
          <input type="text" name="title" className="form-control" />
        </div>

        <div className="mt-3">
          <label className="form-label">
            Ticker Symbol
          </label>
          <input type="text" name="ticker" className="form-control" />
        </div>

        <div className="mt-3">
          <label className="form-label">
            Launch Date
          </label>
          <input type="date" name="launch" className="form-control" />
        </div>
        <div className="mt-3">
          <label className="form-label">
            White paper
          </label>
          <input type="text" name="white_paper" className="form-control" />
        </div>
        <div className="mt-3">
          <label className="form-label">
            Website
          </label>
          <input type="text" name="website" className="form-control" />
        </div>
        <div className="mt-3">
          <label className="form-label">
            Project Description
          </label>
          <input type="text" name="desc" className="form-control" />
        </div>
        <div className="mt-3">
        <label className="form-label">
          Twitter
        </label>
        <input type="text" name="twitter" className="form-control"/>
        </div>
        <div className="mt-3">
        <label className="form-label">
          Discord
        </label>
        <input type="text" name="discord" className="form-control"/>
        </div>
        <div className="mt-3">
        <label className="form-label">
          Telegram
        </label>
        <input type="text" name="telegram" className="form-control"/>
        </div>
      
      
      
        <div className="mt-3">
          <button className="btn btn-primary">Submit</button>
        </div>

        
      </Form>
    </div>
  )
}

export const promoteAction = async ({ request }) => {

  const data = await request.formData()

  const submission = {
    title: data.get('title'),
    ticker: data.get('ticker'),
    launch: data.get('launch'),
    whitepaper:data.get('white_paper'),
    twitter:data.get('twitter'),
    discord:data.get('discord'),
    telegram:data.get('telegram'),


  }
  console.log(submission)
  return true
}



export default Promote