import React from 'react'

const Trending = () => {
  return (
    <div className="container">
        <h3>Whats Hot!</h3>
    </div>
  )
}

export default Trending