const token = [
    {
        token_name: "Token One",
        launch_date: "01/01/2023",
        token_contract_address: "0xE3facb43800204C4bA45335CB4dA0014",
        token_trust: 60,
        raids: 437,
        token_cat: "utility",
        token_votes: 200

    },
    {
        token_name: "Token Two",
        launch_date: "08/01/2023",
        token_contract_address: "0xE3facb43800204C4bA45335CB4dA0014",
        token_trust: 40,
        raids: 128,
        token_cat: "utility",
        token_votes: 39


    }
    ,
    {
        token_name: "Token Three",
        launch_date: "08/01/2023",
        token_contract_address: "0xE3facb43800204C4bA45335CB4dA0014",
        token_trust: 40,
        raids: 128,
        token_cat: "utility",
        token_votes: 39


    },
    {
        token_name: "Token Four",
        launch_date: "10/11/2023",
        token_contract_address: "0xE3facb43800204C4bA45335CB4dA0014",
        token_trust: 10,
        raids: 18,
        token_cat: "meme",
        token_votes: 7


    }
]

const newprojects = [
    {
        id:"0",
        title: "Launch One",
        launch_date: "00/00/0000",
        chain: "56",


    },
    {
        id:"2",
        title: "Launch Two",
        launch_date: "00/00/0000",
        chain: "1",


    },
    {
        id:"3",
        title: "Launch Three",
        launch_date: "00/00/0000",
        chain: "5",


    },
    {
        id:"4",
        title: "Launch Four",
        launch_date: "00/00/0000",
        chain: "5",


    },
    {
        id:"5",
        title: "Launch Five",
        launch_date: "00/00/0000",
        chain: "5",


    },
    {
        id:"6",
        title: "Launch Six",
        launch_date: "00/00/0000",
        chain: "5",


    },
    {
        id:"7",
        title: "Launch Seven",
        launch_date: "00/00/0000",
        chain: "5",


    },
    {
        id:"8",
        title: "Launch Eight",
        launch_date: "00/00/0000",
        chain: "5",


    }

]



export { token, newprojects }