const _HandleUpVote = (current_count) => {
    alert("upvoted", current_count)
    return 5
}

const _HandleNewListing = () => {

}

const _HandleHotList = () => {

}

const _HandleRaidCount = () => {

}

const _HandleCreateArmy = () => {

}

const _HandleCreateAlliance = () => {

}







export {
    _HandleUpVote,
    _HandleNewListing,
    _HandleRaidCount,
    _HandleHotList,
    _HandleCreateAlliance,
    _HandleCreateArmy
}