import React from 'react'
import { useRouteError } from "react-router-dom"



const ErrorPage = () => {

    const error = useRouteError()
    console.log(error)

    return (
        <div className="container text-center">
            <h1>Oops!</h1>
            <p>Sorry, an unexpected error has occured.</p>
            <p></p>

        </div>
    )
}

export default ErrorPage